import { IArchiveElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/archiveView/fields";
import { DictionaryStatuses } from "@monorepo/utils/src/variables/dictionaryStatuses";
import { IFundElement } from "@monorepo/catalog/src/views/FundView/types/fundElement";

interface IConvertApiItemToUi extends IArchiveElement {
  status: string;
  fundsList: IFundElement[];
  fundCount: number;
  inventoryCount: number;
  caseCount: number;
  eadCount: any;
}

export const convertStatus = (archiveIsDeleted: boolean): string => (!archiveIsDeleted ? DictionaryStatuses.ACTIVE : DictionaryStatuses.DELETED);

export const convertApiItemToUi = (item: IArchiveElement): IConvertApiItemToUi => ({
  ...item,
  status: convertStatus(!!item[fields.ARCHIVE_IS_DELETED as keyof IArchiveElement]), // DictionaryStatuses.CONCATED
  fundsList: item.funds || [],
  fundCount: item.countOfFunds,
  inventoryCount: item.countOfInventories,
  caseCount: item.countOfCases,
  eadCount: null,
});
