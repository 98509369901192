import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/archiveView/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Идентификатор архива",
    tooltip: "Идентификатор архива в ЦХЭД",
    defaultWidth: 200,
    value: fields.ARCHIVE_ID,
    isHiddenByDefault: true,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Код архива",
    tooltip: "Код архива (Идентификатор архива при взаимодействии с ОИК)",
    defaultWidth: 100,
    value: fields.ARCHIVE_CODE,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Наименование архива",
    tooltip: "Полное наименование архива",
    defaultWidth: 250,
    value: fields.ARCHIVE_NAME,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Краткое  наименование архива",
    tooltip: "Краткое  наименование архива",
    isLink: false,
    defaultWidth: 200,
    value: fields.ARCHIVE_SHORT_NAME,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Количество фондов",
    tooltip: "Количество фондов",
    defaultWidth: 150,
    value: fields.TABLE_COUNT_OF_FUNDS,
    isLink: false,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Состояние",
    tooltip: "Состояние может принимать следующие значения: Активный, Удалённый",
    isLink: false,
    defaultWidth: 150,
    value: fields.ARCHIVE_STATUS,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Количество дел",
    tooltip: "Количество дел",
    isLink: false,
    defaultWidth: 150,
    value: fields.TABLE_COUNT_OF_CASES,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Количество описей дел",
    tooltip: "Количество описей дел",
    isLink: false,
    defaultWidth: 150,
    value: fields.TABLE_COUNT_OF_INVENTORIES,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Количество ЭАД",
    tooltip: "Количество ЭАД",
    defaultWidth: 180,
    value: fields.INVENTORY_DOCS,
    isLink: false,
    isSorted: false,
    isHandle: true,
    id: uuid(),
    slotName: "inventoryDocs",
  },
];
