import { render, staticRenderFns } from "./ArchiveView.vue?vue&type=template&id=5b8f4e84&scoped=true&"
import script from "./ArchiveView.vue?vue&type=script&lang=ts&"
export * from "./ArchiveView.vue?vue&type=script&lang=ts&"
import style0 from "./ArchiveView.vue?vue&type=style&index=0&id=5b8f4e84&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b8f4e84",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VFadeTransition,VRow,VSpacer})
