import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/archiveView/fields";
import { standardMask } from "@monorepo/utils/src/directives/standardMask";
import { cloneDeep } from "lodash";

export const baseModalElements = [
  {
    id: uuid(),
    isEdited: true,
    title: "Код архива",
    tooltip: "Код архива (Идентификатор архива при взаимодействии с ОИК)",
    className: "info-modal__element_title",
    value: fields.ARCHIVE_CODE,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Краткое наименование архива",
    tooltip: "Краткое  наименование архива",
    className: "info-modal__element_title",
    value: fields.ARCHIVE_SHORT_NAME,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
    maxLength: "50",
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Наименование архива",
    tooltip: "Полное наименование архива",
    className: "info-modal__element_full-size",
    value: fields.ARCHIVE_NAME,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  // {
  //   id: uuid(),
  //   title: "Наименование фонда",
  //   tooltip: "Наименование фонда",
  //   className: "info-modal__element_full-size info-modal__select",
  //   value: fields.ARCHIVE_FUND,
  //   slotName: "selectFund",
  // },
  {
    id: uuid(),
    isEdited: true,
    title: "Адрес",
    tooltip: "Почтовый адрес архива",
    className: "info-modal__element_full-size",
    value: fields.ARCHIVE_ADDRESS,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Описание архива",
    tooltip: "Описание архива",
    className: "info-modal__about info-modal__element_full-size info-modal__element_textarea",
    value: fields.ARCHIVE_DESCRIPTION,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
    maxLength: "20000",
    fieldType: "textarea",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Информация об архиве",
    tooltip: "Информация об архиве",
    className: "info-modal__element_full-size",
    value: fields.CONCATED,
    isHideIfEmptyValue: true,
  },
];

export const countsElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Количество фондов",
    tooltip: "Количество фондов",
    className: "info-modal__element_title",
    value: fields.COUNT_OF_FUNDS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Количество дел",
    tooltip: "Количество дел",
    className: "info-modal__element_title",
    value: fields.COUNT_OF_CASES,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Количество описей дел",
    tooltip: "Количество описей дел",
    className: "info-modal__element_title",
    value: fields.COUNT_OF_INVENTORIES,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Количество ЭАД",
    tooltip: "Количество ЭАД",
    className: "info-modal__element_title",
    value: fields.COUNT_OF_DOCUMENTS,
    isShowContentTooltip: true,
    contentTooltip: (value: string): string => {
      const [received = 0, total = 0] = value?.split(" / ") ?? [];
      return `Получено документов: ${received} из ${total}`;
    },
  },
];

export const techElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор архива",
    tooltip: "Идентификатор архива в ЦХЭД",
    className: "info-modal__element_title",
    value: fields.ARCHIVE_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Состояние",
    tooltip: "Состояние может принимать следующие значения: Активный, Удалённый",
    className: "info-modal__element_title",
    value: fields.ARCHIVE_STATUS,
  },
  {
    id: uuid(),
    title: "Пользователь, сохранивший запись",
    tooltip: "Логин пользователя, сохранившего запись\t",
    className: "info-modal__element_title",
    value: fields.ARCHIVE_CREATE_USER,
  },
  {
    id: uuid(),
    title: "Дата сохранения",
    tooltip: "Дата сохранения записи",
    className: "info-modal__element_title",
    value: fields.ARCHIVE_CREATION_DATE,
  },
  {
    id: uuid(),
    title: "Пользователь, изменивший запись",
    tooltip: "Логин пользователя, изменившего запись\t",
    className: "info-modal__element_title",
    value: fields.ARCHIVE_UPDATE_USER,
  },
  {
    id: uuid(),
    title: "Дата последнего обновления",
    tooltip: "Дата последнего обновления записи",
    className: "info-modal__element_title",
    value: fields.ARCHIVE_UPDATE_DATE,
  },
];

export const fullModalElements = [
  ...baseModalElements,
  ...countsElements,
  ...techElements,
  {
    id: uuid(),
    title: "Редактирование / создание архива",
    className: "info-modal__element_title",
    value: "canEdit",
  },
  {
    id: uuid(),
    title: "Удаление архива",
    className: "info-modal__element_title",
    value: "canDelete",
  },
];
